//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { sysTimersAdd, sysTimersGetActionClasses } from '@/api/modular/system/timersManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      actionClassData: [],
      formLoading: false,
      form: this.$form.createForm(this)
    }
  },
  methods: {
    // 初始化方法
    add (record) {
      this.visible = true
      this.formLoading = true
      this.getActionClass()
    },

    /**
     * 獲取選擇器下拉框數據
     */
    getActionClass () {
      sysTimersGetActionClasses().then((res) => {
         this.formLoading = false
         if (res.success) {
           this.actionClassData = res.data
         } else {
           this.$message.error('獲取選擇器下拉框數據')
         }
      })
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          sysTimersAdd(values).then((res) => {
            if (res.success) {
              this.$message.success('新增成功')
              this.visible = false
              this.confirmLoading = false
              this.$emit('ok', values)
              this.form.resetFields()
            } else {
              this.$message.error('新增失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      this.visible = false
    }
  }
}
