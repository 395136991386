import { axios } from '@/utils/request'

/**
 * 分頁查詢定時任務
 *
 * @author yubaoshan
 * @date 2020/7/3 03:13
 */
export function sysTimersPage (parameter) {
  return axios({
    url: '/sysTimers/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 獲取全部定時任務
 *
 * @author yubaoshan
 * @date 2020/7/3 03:23
 */
export function sysTimersList (parameter) {
  return axios({
    url: '/sysTimers/list',
    method: 'get',
    params: parameter
  })
}

/**
 * 查看詳情定時任務
 *
 * @author yubaoshan
 * @date 2020/7/3 03:23
 */
export function sysTimersDetail (parameter) {
  return axios({
    url: '/sysTimers/detail',
    method: 'get',
    params: parameter
  })
}

/**
 * 添加定時任務
 *
 * @author yubaoshan
 * @date 2020/7/3 03:23
 */
export function sysTimersAdd (parameter) {
  return axios({
    url: '/sysTimers/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 刪除定時任務
 *
 * @author yubaoshan
 * @date 2020/7/3 03:23
 */
export function sysTimersDelete (parameter) {
  return axios({
    url: '/sysTimers/delete',
    method: 'post',
    data: parameter
  })
}

/**
 * 編輯定時任務
 *
 * @author yubaoshan
 * @date 2020/7/3 03:23
 */
export function sysTimersEdit (parameter) {
  return axios({
    url: '/sysTimers/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 獲取系統的所有任務列表
 *
 * @author yubaoshan
 * @date 2020/7/3 03:23
 */
export function sysTimersGetActionClasses (parameter) {
  return axios({
    url: '/sysTimers/getActionClasses',
    method: 'post',
    data: parameter
  })
}

/**
 * 啟動定時任務
 *
 * @author yubaoshan
 * @date 2020/7/3 03:23
 */
export function sysTimersStart (parameter) {
  return axios({
    url: '/sysTimers/start',
    method: 'post',
    data: parameter
  })
}

/**
 * 停止定時任務
 *
 * @author yubaoshan
 * @date 2020/7/3 03:23
 */
export function sysTimersStop (parameter) {
  return axios({
    url: '/sysTimers/stop',
    method: 'post',
    data: parameter
  })
}
